import * as React from "react";

import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const ValueProposition = () => (
    <>
    <section class="about-us-wrapper section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 text-center text-lg-start" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div class="about-img">
                        <img src="/img/box_with_dimensions.png" alt=""/>
                    </div>
                </div>
                <div class="col-lg-7 mt-5 mt-lg-0 ps-lg-5">
                    <div class="block-contents">
                        <div class="section-title">
                            {/* <h5 class="textleft relative">about</h5>
                            <span>Since from 2000</span> */}
                            <h2><FormattedMessage id="value--title-1" /><br/><span><FormattedMessage id="value--title-2" /></span></h2>
                        </div>
                    </div>
                    <div class="about-info-contents">
                        <p><FormattedMessage id="value--desc-1" />
                        </p>
                        <p><FormattedMessage id="value--desc-2" /></p>
                    </div>
                    {/* <div class="company-ceo d-flex align-items-center">
                        <div class="ceo-img bg-cover" style={{backgroundImage: "url('assets/img/man-img.jpg')"}}></div>
                        <div class="ceo-info">
                            <h5>Alexis D. Denzol</h5>
                            <span>Head Of Idea</span>
                        </div>
                        <div class="autograph-signature"><img src="assets/img/signature.png" alt=""/></div>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
    </>
  )