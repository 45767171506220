import * as React from "react";
import Layout from "../components/layout";
// import * as sections from "../components/sections"
// import Fallback from "../components/fallback"
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../LinkTranslated";
import SimpleLocalize from "../SimpleLocalize";
import Preloader from "../components/preloader";
import { PageBanner } from "../components/sections/PageBanner";
import { PageNavBar } from "../components/sections/pageNavBar";
import { ValuePropsHome } from "../components/sections/ValuePropsHome";
import { ServiceFeatures } from "../components/sections/ServiceFeatures";
import { HowWeWork } from "../components/sections/HowWeWork";
import { PriceTable } from "../components/sections/PriceTable";
import { Cta } from "../components/sections/Cta";
import { ValueProposition } from "../components/sections/ValueProposition";

export default function Safe(props) {
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "/js/slick-animation.min.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  return (
    <SimpleLocalize {...props}>
      <Layout>
        <LinkTranslated href="/">
          {/* <FormattedMessage id="header--service-1" /> */}
          {/* <PageBanner textId="header--service-1" imagePath="/img/page-banner.jpg"/> */}
        </LinkTranslated>
        <PageBanner
          textId="header--why"
          imagePath="/img/safe.jpg"
        />
        <PageNavBar
          fromId="header--home"
          currentId="header--features-1"
        ></PageNavBar>
        

        <section class="about-us-wrapper section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 text-center text-lg-start" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div class="about-img" style={{display:'flex', justifyContent:'center'}}>
                        <img src="/img/secure.png" alt=""/>
                    </div>
                </div>
                <div class="col-lg-7 mt-5 mt-lg-0 ps-lg-5">
                    <div class="block-contents">
                        <div class="section-title">
                            <h5 class="textleft relative">Mega</h5>
                            <span><FormattedMessage id="header--features-1" /></span>
                            {/* <h2><FormattedMessage id="value--title-1" /><span><FormattedMessage id="value--title-2" /></span></h2> */}
                        </div>
                    </div>
                    <div class="about-info-contents">
                        <p><FormattedMessage id="why1--story-1" />
                        </p>
                        <p><FormattedMessage id="why1--story-2" /></p>
                    </div>
                    {/* <div class="company-ceo d-flex align-items-center">
                        <div class="ceo-img bg-cover" style={{backgroundImage: "url('assets/img/man-img.jpg')"}}></div>
                        <div class="ceo-info">
                            <h5>Alexis D. Denzol</h5>
                            <span>Head Of Idea</span>
                        </div>
                        <div class="autograph-signature"><img src="assets/img/signature.png" alt=""/></div>
                    </div> */}
                </div>
            </div>
        </div>
    </section>

    <section class="what-we-do section-padding">
        <div class="container">
            <div class="col-12 col-lg-12 mb-40">
                <div class="block-contents text-center">
                    <div class="section-title">
                        <h5>Mega</h5>
                        <h2><FormattedMessage id="why1--feature-title-1" /><span><FormattedMessage id="why1--feature-title-2" /></span></h2>
                    </div>
                </div>
            </div>

            <div class="row text-center">
            <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-feature-item">
                        <div class="icon">
                            <img src="/img/thermometer.png" alt=""/>
                        </div>
                        <div class="content">
                            {/* <h2>9 million</h2> */}
                            <span><FormattedMessage id="why1--feature1-title" /></span>
                            <p><FormattedMessage id="why1--feature1-desc" /></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-feature-item">
                        <div class="icon">
                            <img src="/img/lock.png" alt=""/>
                        </div>
                        <div class="content">
                            {/* <h2>9 million</h2> */}
                            <span><FormattedMessage id="why1--feature2-title" /></span>
                            <p><FormattedMessage id="why1--feature2-desc" /></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-feature-item">
                        <div class="icon">
                            <img src="/img/alarm.png" alt=""/>
                        </div>
                        <div class="content">
                            {/* <h2>25 million</h2> */}
                            <span><FormattedMessage id="why1--feature3-title" /></span>
                            <p><FormattedMessage id="why1--feature3-desc" /></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-feature-item">
                        <div class="icon">
                            <img src="/img/insect.png" alt=""/>
                        </div>
                        <div class="content">
                            {/* <h2>2 million</h2> */}
                            <span><FormattedMessage id="why1--feature4-title" /></span>
                            <p><FormattedMessage id="why1--feature4-desc" /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


        {/* <section class="service-details-wrapper">
          <div class="container">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-xl-10 col-12 pe-xl-5">
                <div class="service-details-contents">
                  <div class="contents">
                    <h2>
                      <FormattedMessage id="header--features-1" />
                    </h2>
                    <div class="service-feature-img" style={{display:'flex', justifyContent:'center'}}>
                      <img src="/img/one-stop.png" alt="" style={{width:'100%', maxWidth:'700px'}}/>
                    </div>
                    <p>
                      <FormattedMessage id="why1--story-1" />
                    </p>
                    <p>
                      <FormattedMessage id="why1--story-2" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "120px" }} />
          <div class="container">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-xl-10 col-12 pe-xl-5 right">
                <div class="service-details-contents">
                  <div class="contents">
                    <h2>
                      <FormattedMessage id="service1--feature-2-title" />
                    </h2>
                    <div class="service-feature-img"  style={{display:'flex', justifyContent:'center'}}>
                      <img src="/img/secure.png" alt="" style={{width:'100%',maxWidth:'700px'}}/>
                    </div>
                    <p>
                      <FormattedMessage id="service1--feature-2-desc" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <div style={{ height: "80px" }} />
        <ServiceFeatures />
        <div style={{ height: "120px" }} />
        {/* <HowWeWork /> */}
        {/* <PriceTable /> */}
        <ValueProposition />
      </Layout>
    </SimpleLocalize>
  );
}
