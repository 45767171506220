import * as React from "react";

//translation
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const ServiceFeatures = () => (
    <>
      <section class="our-service-solution section-bg section-padding">
        <div class="container">
            <div class="col-lg-8 offset-lg-2 text-center col-12">
            <div className="block-contents text-center">
              <div className="section-title">
                <h5>Mega</h5>
                <span><FormattedMessage id="home--features-subtitle" /></span>
                <h2>
                  Mega <span><FormattedMessage id="home--features-title" /></span>
                </h2>
              </div>
            </div>
            </div>

            <div class="row">
                <div class="col-xl-3 col-md-6 col-12">
                    <div class="single-freight-service wow fadeInUp">
                        <h3><LinkTranslated href="/safe"><FormattedMessage id="features--1" /></LinkTranslated></h3>
                        {/* <p>Road transport and industry driving coordinations experience</p> */}
                        <div class="checked-list right">
                            <li><FormattedMessage id="features--1-1" /></li>
                            <li><FormattedMessage id="features--1-2" /></li>
                            <li><FormattedMessage id="features--1-3" /></li>
                            <li><FormattedMessage id="features--1-4" /></li>
                        </div>
                        <div class="icon"><i class="flaticon-warehouse"></i></div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 col-12">
                    <div class="single-freight-service wow fadeInUp" data-wow-delay=".3s">
                        <h3><LinkTranslated href="/system"><FormattedMessage id="features--2" /></LinkTranslated></h3>
                        {/* <p>Ocean freight gives organization and industry excellent experience</p> */}
                        <div class="checked-list right">
                        <li><FormattedMessage id="features--2-1" /></li>
                            <li><FormattedMessage id="features--2-2" /></li>
                            <li><FormattedMessage id="features--2-3" /></li>
                            <li><FormattedMessage id="features--2-4" /></li>
                        </div>
                        <div class="icon"><i class="flaticon-approval"></i></div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 col-12">
                    <div class="single-freight-service wow fadeInUp" data-wow-delay=".6s">
                        <h3><LinkTranslated href="/professional"><FormattedMessage id="features--3" /></LinkTranslated></h3>
                        {/* <p>Ocean freight gives organization and industry excellent experience</p> */}
                        <div class="checked-list right">
                        <li><FormattedMessage id="features--3-1" /></li>
                            <li><FormattedMessage id="features--3-2" /></li>
                            <li><FormattedMessage id="features--3-3" /></li>
                            <li><FormattedMessage id="features--3-4" /></li>
                        </div>
                        <div class="icon"><i class="flaticon-deliver"></i></div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 col-12">
                    <div class="single-freight-service wow fadeInUp" data-wow-delay=".9s">
                        <h3><LinkTranslated href="/logistics"><FormattedMessage id="features--4" /></LinkTranslated></h3>
                        {/* <p>Ocean freight gives organization and industry excellent experience</p> */}
                        <div class="checked-list right">
                        <li><FormattedMessage id="features--4-1" /></li>
                            <li><FormattedMessage id="features--4-2" /></li>
                            <li><FormattedMessage id="features--4-3" /></li>
                            <li><FormattedMessage id="features--4-4" /></li>
                        </div>
                        <div class="icon"><i class="flaticon-delivery-truck-1"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );