import * as React from "react";

//translation
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const PageNavBar = (props) => (
    <>
      <div class="breadcrumb-wrapper">
        <div class="container">
            <nav>
                <ol class="breadcrumb" style={{alignItems:'center'}}>
                  <li class="breadcrumb-item"><LinkTranslated href="/"><FormattedMessage id={props.fromId} /></LinkTranslated></li>
                  <li class="breadcrumb-item active" aria-current="page"><FormattedMessage id={props.currentId} /></li>
                </ol>
            </nav>
        </div>
    </div>
    </>
  );