import * as React from "react";

//translation
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const Cta = () => (
  <>
    <section
      className="cta-wrapper section-padding bg-overlay bg-cover "
      style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('/img/contact.jpg')"}}
    >
      <div className="container">
        <div className="col-lg-8 offset-lg-2 col-12 text-center">
          <div className="cta-contents">
            {/* <img src="/img/footer-logo.png" alt="megastorage" /> */}
            <h1 className="text-white mt-5 mb-4">
            <FormattedMessage id="cta--title" />
            </h1>
            
            <LinkTranslated href="/quote" className="theme-btn">
            <FormattedMessage id="cta--button" />
            </LinkTranslated>
          </div>
        </div>
      </div>
    </section>
  </>
);
