import * as React from "react";

//translation
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const PriceTable = () => (
  <>
    <section className="our-price-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block-contents mb-60">
              <div className="section-title text-center">
                <h5 className="text-center">Mega</h5>
                <span>
                  <FormattedMessage id="home--products-subtitle" />
                </span>
                <h2>
                  <FormattedMessage id="home--products-title-1" />
                  <span>
                    <FormattedMessage id="home--products-title-2" />
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="single-price-bar">
          <div className="row align-items-center">
            <div className="col-xl-2">
              <div className="package-name">
                <h3>
                  <FormattedMessage id="home--product1-title1" />
                  <br />
                  <FormattedMessage id="home--product1-title2" />
                </h3>
              </div>
            </div>
            <div className="col-xl-3">
              <div
                className="package-image"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src="/img/box-small.png" alt="small box"></img>
              </div>
            </div>
            <div className="col-xl-5 pe-xl-0">
              <div className="package-features">
                <span>
                  <FormattedMessage id="home--product1-desc-1" />
                </span>
                <br />
                <span>
                  <FormattedMessage id="home--product1-desc-2" />
                </span>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="package-price">
                <h2>
                  <span>
                    <FormattedMessage id="home--product1-price" />
                  </span>
                </h2>
              </div>
            </div>
            {/* <div className="col-xl-3 text-xl-end">
                  <a href="#" className="theme-btn minimal-btn">
                    get started <i className="fal fa-long-arrow-right"></i>
                  </a>
                </div> */}
          </div>
        </div>
        <div className="single-price-bar">
          <div className="row align-items-center">
            <div className="col-xl-2">
              <div className="package-name">
                <h3>
                  <FormattedMessage id="home--product2-title1" />
                  <br />
                  <FormattedMessage id="home--product2-title2" />
                </h3>
              </div>
            </div>
            <div className="col-xl-3">
              <div
                className="package-image"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src="/img/box-big.png" alt="big box"></img>
              </div>
            </div>
            <div className="col-xl-5 pe-xl-0">
              <div className="package-features">
                <span>
                  <FormattedMessage id="home--product2-desc-1" />
                </span>
                <br />
                <span>
                  <FormattedMessage id="home--product2-desc-2" />
                </span>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="package-price">
                <h2>
                  <span>
                    <FormattedMessage id="home--product2-price" />
                  </span>
                </h2>
              </div>
            </div>
            {/* <div className="col-xl-3 text-xl-end">
                  <a href="#" className="theme-btn minimal-btn">
                    get started <i className="fal fa-long-arrow-right"></i>
                  </a>
                </div> */}
          </div>
        </div>
        {/* <div className="single-price-bar active">
              <div className="row align-items-center">
                <div className="col-xl-2">
                  <div className="package-name">
                    <h3>Business Pack</h3>
                  </div>
                </div>
                <div className="col-xl-5 pe-xl-0">
                  <div className="package-features">
                    <span>Pro business rules</span>
                    <span>10 warehouse</span>
                    <span>Real-time rate</span>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="package-price">
                    <h2>
                      <span>149</span>USD
                    </h2>
                  </div>
                </div>
                <div className="col-xl-3 text-xl-end">
                  <a href="#" className="theme-btn minimal-btn">
                    get started <i className="fal fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div> */}
      </div>
    </section>
  </>
);
