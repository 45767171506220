import * as React from "react";

//translation
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const PageBanner = (props) => (
    <>
      <div class="page-banner-wrap text-center bg-cover" style={{backgroundImage: "url('" + props.imagePath + "')"}}>
        <div class="container">
            <div class="page-heading text-white">
                <h1><FormattedMessage id={props.textId} /></h1>
            </div>
        </div>
    </div>
    </>
  );