import * as React from "react";

import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const HowWeWork = () => (
    <>
      <section class="work-process-wrapper section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 mb-40">
              <div class="block-contents text-center">
                <div class="section-title">
                  <h5>Mega</h5>
                  <span><FormattedMessage id="how--subtitle" /></span>
                  <h2><FormattedMessage id="how--title" /></h2>
                </div>
              </div>
            </div>
          </div>

          <div class="work-steps-list">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-12">
                <div class="single-work-steps">
                  <div class="icon">
                    <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <img src="/img/icons/step1.png" alt="" />
                    </div>
                  </div>
                  <div class="content">
                    <h4><FormattedMessage id="how--1-title" /></h4>
                    <p>
                    <FormattedMessage id="how--1-desc" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <div class="single-work-steps">
                  <div class="icon">
                  <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src="/img/icons/step2.png" alt="" />
                    </div>
                  </div>
                  <div class="content">
                    <h4><FormattedMessage id="how--2-title" /></h4>
                    <p>
                    <FormattedMessage id="how--2-desc" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <div class="single-work-steps">
                  <div class="icon">
                  <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src="/img/icons/step3.png" alt="" />
                    </div>
                  </div>
                  <div class="content">
                    <h4><FormattedMessage id="how--3-title" /></h4>
                    <p>
                    <FormattedMessage id="how--3-desc" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <div class="single-work-steps">
                  <div class="icon">
                  <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src="/img/icons/step4.png" alt="" />
                    </div>
                  </div>
                  <div class="content">
                    <h4><FormattedMessage id="how--4-title" /></h4>
                    <p>
                    <FormattedMessage id="how--4-desc" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
